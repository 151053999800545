body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  transition-duration: 500ms;
}

::-webkit-scrollbar {
  width: 6px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: #4b4854; /* Cor de fundo da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background: #666360; /* Cor do controle deslizante (thumb) */
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999591; /* Cor do controle deslizante (thumb) ao passar o mouse */
}
